import React from "react";
import classnames from "classnames";
import { motion } from "framer-motion";
import Hero from "../components/hero.js";
import ThreeCol from "../components/three-col";
import TwoColumnFeature from "../components/TwoColFeature";
import CTA from "../components/cta";

const Home = () => {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			className="relative left-0"
		>
			<Hero />
			<ThreeCol />
			<TwoColumnFeature />
			<CTA />
		</motion.div>
	);
};

export default Home;
