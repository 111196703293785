import React, { useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM
import VideoModal from './VideoModal';
import VideoImage from '../images/video-egi.png';
import BgImgMobile from "../images/Ergo_BGD-mobile@2x.png";
import VideoDesktop from "../videos/200030_Ergonomic_BG_Loop_20s.mp4"; // Import your video file

const Hero = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handle1ButtonClick = () => {
		window.open('https://www.ergogroup.com/contact-us/', '_blank', 'noopener noreferrer');
	};

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const vimeoIframe = (
		<div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
			<iframe
				src="https://player.vimeo.com/video/853694041?h=344c0eff5b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
				frameBorder="0"
				allow="autoplay; fullscreen; picture-in-picture"
				style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
				title="Ergonomic Group - Interactive Guide"
			></iframe>
		</div>
	);
	const backgroundImage = window.innerWidth <= 640 ? `url(${BgImgMobile})` : 'none'; // Set none for mobile
	return (
		<div className="parallax-container bg-hero-image lg:px-[50px] xl:px-[0]" style={{ backgroundImage }}>
			<div className="parallax-bg"  />
			<div className="container relative z-40 max-w-[1200px] w-[100%] lg:flex pt-[90px] pb-[75px] lg:pt-[160px] lg:pb-[160px] m-auto align-middle">
				<div className="w-[100%] p-[33px] pt-0 lg:p-[0] lg:pr-[30px] lg:w-1/2">
					<div className={`scroll-animation visible`}>
						<h1 className="text-[28px] leading-[38px] lg:text-[40px] lg:leading-[55px] text-[#ffffff] mb-[20px]">Discover the Ergonomic <span className="md:block"></span>Group Difference</h1>
						<p className="text-[15px] leading-[22px] lg:text-[19px] lg:leading-[28px] text-[#ffffff] mb-4">
							The Ergonomic Group (EGI), a privately-held women-owned company, connects your industry to digital solutions tailored to support healthcare, financial services, and public sector organizations. From our own infrastructure and software-as-a-service solutions to data and backup protection solutions from our partner Dell Technologies, EGI can help you chart your digital future.
							<br/><br/>
							See how you can address your most pressing business challenges with EGI.
						</p>
						<div className="flex space-x-4">
							<button
								onClick={openModal}
								className="xs:text-[10px]  video-lightbox bg-[#EDB425] hover:bg-[#000000] hover:text-[#ffffff] font-bold sm:text-[15px] text-[#071A3E] flex items-center px-3 py-2 sm:py-[10px] sm:px-[25px] lg:px-[25px] lg:py-[11px] rounded-[50px]">
								<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2">
									<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#068365"/>
								</svg>
								Watch the Video
							</button>
							<button
								onClick={handle1ButtonClick}
								className="xs:text-[10px] bg-[#EDB425] hover:bg-[#000000] hover:text-[#ffffff] font-bold sm:text-[15px] text-[#071A3E] flex items-center px-3 py-2 sm:py-[10px] sm:px-[25px]  lg:px-[25px] lg:py-[11px] rounded-[50px]">
								<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2">
									<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#068365"/>
								</svg>
								Talk With Us
							</button>
						</div>
					</div>
				</div>
				<div className="w-[100%] mt-[38px] lg:mt-[0] lg:w-1/2 lg:pl-[40px]">
					<div className={`scroll-animation visible`}>
							<button className="video-lightbox w-[100%] mt-[10px]" onClick={openModal}>
								<img src={VideoImage} width={'100%'} height={'auto'} alt="Video Thumbnail" />
								<div className="play-button"></div>
							</button>
					</div>
				</div>

			</div>
			<div className="video-container fixed z-1 top-0 left-0"> {/* Video Container */}
				<video autoPlay loop muted className="video-element">
					<source src={VideoDesktop} type="video/mp4" />
				</video>
			</div>
			<VideoModal isOpen={isModalOpen} onRequestClose={closeModal} vimeoIframe={vimeoIframe} />
		</div>
	);
};

export default Hero;