import React, { useRef, useEffect, useState } from 'react';
import FeatureImg1 from '../images/incompass-feature@2x.png';
import FeatureImg2 from '../images/dell-feature@2x.png';
import LogoImg1 from '../images/incompass.svg';
import LogoImg2 from '../images/dell-technologies-logo-2.svg';

const TwoColumnFeature = () => {
	const handle1ButtonClick = () => {
		window.open('https://www.ergogroup.com/incompass/', '_blank', 'noopener noreferrer');
	};
	const handle2ButtonClick = () => {
		window.open('https://www.ergogroup.com/our-partnerships/dell-emc/', '_blank', 'noopener noreferrer');
	};

	const [isVisible, setIsVisible] = useState(false);
	// Create separate refs for each element
	const elementRef1 = useRef(null);
	const elementRef2 = useRef(null);
	useEffect(() => {
		const handleIntersection = (entries) => {
			const [entry] = entries;
			setIsVisible(entry.isIntersecting || isVisible);
		};



		const observerOptions = {
			threshold: 0,
		};

		// Use separate Intersection Observer for each element
		const observer1 = new IntersectionObserver(handleIntersection, observerOptions);
		const observer2 = new IntersectionObserver(handleIntersection, observerOptions);
		const observer3 = new IntersectionObserver(handleIntersection, observerOptions);

		if (elementRef1.current) {
			observer1.observe(elementRef1.current);
		}
		if (elementRef2.current) {
			observer2.observe(elementRef2.current);
		}

		return () => {
			observer1.disconnect();
			observer2.disconnect();
			observer3.disconnect();
		};
	}, [isVisible]);

	return (
		<div>
			<div className="container max-w-[1200px] mx-auto lg:mb-[100px]">
				<div ref={elementRef2} className={`scroll-animation ${isVisible ? 'visible' : ''}`}>
					<div className="flex flex-wrap">
						<div className="w-full lg:w-1/2 pr-[0] lg:pr-[10px] lg:mb-6 flex flex-col justify-between">
							<div className="bg-[#F0EFEF] h-full text-center lg:text-left">
								<img
									src={FeatureImg1}
									alt="Feature 1"
									className="w-full h-auto"
								/>
								<div className="p-5 pb-[20px]">
									<img
										src={LogoImg1}
										alt="Logo 1"
										className="w-full max-w-[268px] lg:mt-[40px] mt-[40px] h-auto m-auto lg:m-0"
									/>
									<h3 className="mt-4 text-[21px] font-bold text-[#068365]">
										Make complex, end-to-end workflow manageable
									</h3>
									<p className="mt-4 text-[15px] md:text-[19px]">EGI’s patented Incompass<sup>®</sup> web-based application reduces business management complexity with configurable tools that manage procurement, financial analytics, and cloud management. Choose from 7 pre-built modules that can be customized to meet your business, IT, and financial requirements.</p>
									<button
										onClick={handle1ButtonClick}
										className="group bg-[#000000] hover:bg-[#ffffff] border border-[#000000] hover:text-[#000000] lg:mt-[30px] text-[#ffffff] flex font-bold items-center px-[25px] py-[11px] rounded-[50px] lg:mb-[40px] m-auto mt-[30px] mb-[40px] lg:m-0">
										<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2 group-hover:hidden">
											<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#EDB425"/>
										</svg>
										<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2 group-hover:block hidden">
											<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#068365"/>
										</svg>
										Learn More
									</button>
								</div>
							</div>
						</div>

						<div className="w-full lg:w-1/2 pl-[0] lg:pl-[20px] lg:mb-6 flex flex-col justify-between">
							<div className="bg-[#F0EFEF] h-full text-center lg:text-left">
								<img
									src={FeatureImg2}
									alt="Feature 2"
									className="w-full h-auto"
								/>
								<div className="p-5 pb-[20px]">
									<img
										src={LogoImg2}
										alt="Logo 2"
										className="w-full max-w-[268px] lg:m-0 lg:mt-[61px] mt-[40px] h-auto m-auto "
									/>
									<h3 className="mt-[43px] md:mt-4 text-[21px] font-bold text-[#068365]">
										Leverage EGI’s Partnership with Dell Technologies to Transform Your Business
									</h3>
									<p className="mt-[27px] md:mt-4  text-[15px] md:text-[19px]">
										When you partner with EGI, you leverage our expertise with Dell Technologies’ hybrid cloud and Big Data solutions. Together we can help you build and maintain a modern data center infrastructure that incorporates converged infrastructure, servers, storage, and cybersecurity technologies into a solution that meets your needs.
									</p>
									<button
										onClick={handle2ButtonClick}
										className="group bg-[#000000] hover:bg-[#ffffff] border border-[#000000] hover:text-[#000000] lg:mt-[30px] text-[#ffffff] flex font-bold items-center px-[25px] py-[11px] rounded-[50px] lg:mb-[40px] m-auto mt-[30px] mb-[40px] lg:m-0">
										<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2 group-hover:hidden">
											<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#EDB425"/>
										</svg>
										<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2 group-hover:block hidden">
											<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#068365"/>
										</svg>
										Learn More
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TwoColumnFeature;