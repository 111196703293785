import { HashRouter as Router } from "react-router-dom";
import Navigation from "./components/navigation";
import AnimatedRoutes from "./templates/AnimatedRoutes";
import "./scss/app.scss";
import Footer from "./components/footer";
import React from "react";

function App() {
    return (
        <div className="App w-[100vw] top-0 bg-gradient-to-b from-[#ffffff] to-[#aaaaaa]">
            <Router>
                <Navigation />
                <AnimatedRoutes />
                <Footer />
            </Router>
        </div>
    );
}

export default App;
