import React from 'react';
import DellLogo from "../images/DT_TitaniumPartner_Blue.svg";
import ErgoLogo from "../images/ergo-logo.svg";

const Navigation = () => {


	return (
		<nav className={`w-[100%] relative z-40 bg-white lg:pb-[30px] lg:pt-0 pb-[30px] pt-[30px]`}>
			<div className="lg:flex flex-wrap max-w-[1200px] justify-end mx-auto w-[90%] flex-row hidden">
				<div className={`bg-[#068365] text-[16px] pt-[21px] pl-[14px] pb-[19px] pr-[14px] top-[0] text-[#ffffff] hidden lg:block`}>
					100% Women-Owned Business
				</div>
			</div>
			<div className="flex flex-wrap justify-between max-w-[1200px] w-[90%] items-end mx-auto w-[100%] flex-row">
				<img src={ErgoLogo} alt="Logo 1" className={`lg:h-[109px] h-[76px] `} />
				<img src={DellLogo} alt="Logo 2" className={`h-auto lg:w-[211px] w-[130px]`} />
			</div>
		</nav>
	);
};

export default Navigation;
