import React from 'react';
import ErgoLogo from "../images/ergo-logo.svg";
import Twitter from "../images/twitter.svg";
import Linked from "../images/linkedin.svg";
import Facebook from "../images/facebook.svg";


const Navigation = () => {
	return (
		<footer className="bg-[#000000] p-4 flex justify-between items-center pt-[50px] pb-[80px]">
			<div className="flex container max-w-[1200px] w-[100%] m-auto flex-wrap">
				<div className={'row w-[100%] text-center lg:text-left'}>
					<img src={ErgoLogo} alt="Logo 1" className="h-[109px] m-auto lg:m-0" />
				</div>
				<div className={'row w-[100%] lg:flex mt-[27px] text-center lg:text-left'}>
					<div className="w-[100%] lg:w-1/2 text-[#ffffff]">
						<span className="font-bold">New York (HQ)</span><br/>
						200 Robbins Lane Jericho, NY 1173<br/>
						<span className="text-[#EDB425]">P</span>: 516.746.7777 <span className="text-[#EDB425]">E</span>: info@ergogroup.com
					</div>
					<div className="lg:w-1/2 text-[#ffffff] mt-[20px] block  lg:hidden">
						<span className="font-semibold">Offices In:</span><br/>
						NYC • Boston • CT
					</div>
					<div className="w-[100%] mt-[20px] lg:mt-[0] lg:w-1/2 text-[#ffffff]">
						<div className="mb-[10px]">
							<span className="font-bold">Follow Us Online</span>
						</div>
						<div className="flex flex-wrap flex-row justify-center lg:justify-start">
							<a href={'http://www.facebook.com/pages/The-Ergonomic-Group/162635063760524'} target={'_blank'}><img src={Facebook} className="mr-[10px] w-[40px] h-auto" /></a>
							<a href={'https://www.linkedin.com/company/ergonomicgroup/'} target={'_blank'}><img src={Linked} className="mr-[10px] w-[40px] h-auto" /></a>
							<a href={'http://www.twitter.com/ergonomicgroup'} target={'_blank'}><img src={Twitter} className="w-[40px] h-auto"/></a>
						</div>
					</div>
				</div>
				<div className={'row w-[100%] lg:flex lg:mt-[27px] text-center lg:text-left'}>
					<div className="lg:w-1/2 text-[#ffffff] invisible lg:visible">
						<span className="font-semibold">Offices In:</span><br/>
						NYC • Boston • CT
					</div>
					<div className="w-[90%] m-auto lg:w-1/2 text-[#ffffff] text-[15px] leading-[28px]">
						© Copyright 2023, The Ergonomic Group, All rights reserved.<br/>
						<a href={'https://www.ergogroup.com/privacy-policy/'} target={'_blank'} className={'text-white text-decoration-none'}>Privacy Policy</a>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Navigation;