import React, {useEffect, useRef, useState} from 'react';
import Heart from '../images/heart.svg';
import Money from '../images/money.svg';
import Bank from '../images/bank.svg';

const ThreeCol = () => {
	const [isVisible, setIsVisible] = useState(false);
	// Create separate refs for each element
	const elementRef1 = useRef(null);
	const elementRef2 = useRef(null);
	const elementRef3 = useRef(null);
	const elementRef = useRef(null);
	useEffect(() => {
		const handleIntersection = (entries) => {
			const [entry] = entries;
			setIsVisible(entry.isIntersecting || isVisible);
		};



		const observerOptions = {
			threshold: 0,
		};

		// Use separate Intersection Observer for each element
		const observer1 = new IntersectionObserver(handleIntersection, observerOptions);
		const observer2 = new IntersectionObserver(handleIntersection, observerOptions);
		const observer3 = new IntersectionObserver(handleIntersection, observerOptions);

		if (elementRef1.current) {
			observer1.observe(elementRef1.current);
		}
		if (elementRef2.current) {
			observer2.observe(elementRef2.current);
		}
		if (elementRef3.current) {
			observer3.observe(elementRef3.current);
		}

		return () => {
			observer1.disconnect();
			observer2.disconnect();
			observer3.disconnect();
		};
	}, [isVisible]);

	return (
		<div className="bg-[#ffffff] z-40 relative lg:pt-[109px] lg:pb-[120px] pt-[50px] pb-[50px]">
			<div className="container flex justify-center w-[100%] m-auto">
				<div className="lg:mb-[40px]">
					<div ref={elementRef} className={`scroll-animation ${isVisible ? 'visible' : ''}`}>
						<h2 className="text-[28px] lg:text-[40px] text-[#068365] mb-[20px] text-center">Remain Competitive in an Everchanging Digital Landscape</h2>
					</div>
				</div>
			</div>
			<div className="container max-w-[1200px] lg:flex justify-between m-auto">

				<div className="w-[100%] lg:w-1/3 p-6">
					<div ref={elementRef1} className={`scroll-animation ${isVisible ? 'visible' : ''}`}>
						<div className="">
							<figure className="flex justify-center align-center mb-[20px]">
								<img src={Heart} className="w-[75px] h-auto md:w-[111px]"/>
							</figure>
							<p className="text-center lg:text-left text-[15px] md:text-[19px]">
								Groundbreaking healthcare innovations require additional storage
								capacity and distributed access to protected data. In an industry
								under pressure to innovate and contain costs, EGI helps you safeguard
								data, optimize budgets, and better allocate resources.
							</p>
						</div>
					</div>
				</div>
				<div className="w-[100%] lg:w-1/3 p-6">
					<div ref={elementRef2} className={`scroll-animation ${isVisible ? 'visible' : ''}`}>
						<div className="">
							<figure className="flex justify-center align-center mb-[20px]">
								<img src={Money} className="w-[65px] h-auto md:w-[97px]"/>
							</figure>
							<p className="text-center lg:text-left text-[15px] md:text-[19px]">
								Leaders in the financial services and insurance sectors need to focus
								more time on revenue building, and less time on administrative tasks
								and infrastructure. EGI’s hyperconverged solutions and “as-a-service”
								products protect data, help you streamline costs, and provide analytical
								insights that drive business decisions.
							</p>
						</div>
					</div>
				</div>
				<div className="w-[100%] lg:w-1/3 p-6">
					<div ref={elementRef3} className={`scroll-animation ${isVisible ? 'visible' : ''}`}>
						<div className="">
							<figure className="flex justify-center align-center mb-[20px]">
								<img src={Bank} className="w-[63px] h-auto md:w-[93px]"/>
							</figure>
							<p className="text-center lg:text-left text-[15px] md:text-[19px]">
								Spikes in cyberattacks and customer demand require government agencies,
								schools, and other organizations in the public sector to keep pace with
								digital transformation. With EGI as your partner, you can remain vigilant
								in your data protection efforts as you expand your services to customers.
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ThreeCol;