import React, {useEffect, useRef, useState} from 'react';
import BgImgMobile from '../images/Ergo_BGD-banner-mobile@2x.png';
import BgImgDesktop from '../images/Ergo_BGD-banner@2x.png';
import VideoDesktop from "../videos/200030_Ergonomic_BG_Loop_20s.mp4";

const CTA = () => {
	const [isVisible, setIsVisible] = useState(false);
	const elementRef = useRef(null);
	const handle1ButtonClick = () => {
		window.open('https://www.ergogroup.com/contact-us/', '_blank', 'noopener noreferrer');
	};

	useEffect(() => {
		const handleIntersection = (entries) => {
			const [entry] = entries;
			setIsVisible(entry.isIntersecting || isVisible);
		};

		const observerOptions = {
			threshold: 0,
		};

		if (elementRef.current) {
			const observer = new IntersectionObserver(handleIntersection, observerOptions);

			const viewportWidth = window.innerWidth;

			let rootMargin = '-150px'; // Default root margin

			if (viewportWidth <= 640) {
				rootMargin = '-50px'; // Adjust for mobile screen
			} else if (viewportWidth <= 1024) {
				rootMargin = '-100px'; // Adjust for tablet screen
			}

			observer.observe(elementRef.current);

			return () => {
				observer.disconnect();
			};
		}
	}, [isVisible]);
	const backgroundImage = window.innerWidth <= 640 ? `url(${BgImgMobile})` : 'none'; // Set none for mobile
	return (
		<div className="parallax-container bg-hero-image relative overflow-hidden" style={{ backgroundImage }}>
			<div className="container relative z-40 max-w-[1200px] w-[100%] flex pt-[114px] pb-[114px] lg:pt-[160px] lg:pb-[160px] m-auto">
				<div className="w-[90%] m-auto lg:w-[100%] text-center">
					<div ref={elementRef} className={`scroll-animation ${isVisible ? 'visible' : ''}`}>
					<h3 className="text-[28px] lg:text-[40px] text-[#ffffff] mb-[27px] md:mb-[20px] text-center">Dive Deeper into EGI and Dell Technologies' solutions.</h3>
					<div className="flex space-x-4 justify-center">
						<button
							onClick={handle1ButtonClick}
							className="bg-[#EDB425] hover:bg-[#000000] hover:text-[#ffffff] font-bold text-[#071A3E] flex items-center px-[25px] py-[11px] rounded-[50px]">
							<svg xmlns="http://www.w3.org/2000/svg" width="15.166" height="13.945" viewBox="0 0 15.166 13.945" className="mr-2">
								<path id="Path_30" data-name="Path 30" d="M3.056,6.778.279,13.945,15.167,6.667,0,0Z" transform="translate(0 0)" fill="#068365"/>
							</svg>
							Talk With Us
						</button>
					</div>
					</div>
				</div>
			</div>
			<div className="video-container fixed z-1 top-0 left-0"> {/* Video Container */}
				<video autoPlay loop muted className="video-element">
					<source src={VideoDesktop} type="video/mp4" />
				</video>
			</div>
		</div>
	);
};

export default CTA;