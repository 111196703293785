import React from "react";
import {
	Routes, // instead of "Switch"
	Route,
	useLocation,
} from "react-router-dom";
import Home from "./Home";
import { AnimatePresence } from "framer-motion";


function AnimatedRoutes() {
	const location = useLocation();
	return (
		<main id="main" className="animatedRoutes">
			<AnimatePresence mode="wait" initial={false}>
				<Routes location={location} key={location.pathname}>
					<Route path="/" element={<Home />} />
				</Routes>
			</AnimatePresence>
		</main>

	);
}

export default AnimatedRoutes;
