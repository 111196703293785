import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const VideoModal = ({ isOpen, onRequestClose, vimeoIframe }) => {
	const modalContent = (
		<div className={`modal ${isOpen ? 'open' : ''}`}>
			<div className="modal-overlay" onClick={onRequestClose}></div>
			<div className="modal-body">
				<div className="modal-video">
					{vimeoIframe}
				</div>
			</div>
		</div>
	);

	return isOpen ? modalContent : null;
};

VideoModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onRequestClose: PropTypes.func.isRequired,
	vimeoIframe: PropTypes.element.isRequired,
};

export default VideoModal;
